import { IRowData } from 'interfaces/IConfigureConnection';
import { IFormField } from 'interfaces/IForm';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAuthorizationParameterValue, setAccessTokenParameterValue } from 'store/actions/generalActions';
import { extractFormIndex } from 'utils/extractFormIndex';

const useConfigureConnectionTable = (field: IFormField, value: any, setValue: any) => {
    const initialRowData: IRowData[] = [];

    const [defaultRowData, setDefaultRowData] = useState<IRowData[]>([]);
    const [index, setIndex] = useState<number>(initialRowData.length);
    const [accessTokenError, setAccessTokenError] = useState<boolean>(false);
    const [authorizationError, setAuthorizationError] = useState<boolean>(false);
    const [keyEmptyError, setKeyEmptyError] = useState<boolean>(false);
    const dispatch = useDispatch();

    const formIndex = extractFormIndex(field.inputName);

    const handlePropertyAdd = () => {
        setIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            const newRow: IRowData = { id: newIndex, Key: ``, Value: '' };
            setDefaultRowData((prevData) => [...prevData, newRow]);
            return newIndex;
        });
    };

    const handleDelete = (id: number) => {
        setDefaultRowData((prevData) => prevData.filter((row) => row.id !== id));
    };

    const handleRowData = (data: string, param: any) => {
        setDefaultRowData((prevData) => prevData.map((row) => (row.id === param.data.id ? { ...row, [param.colDef.field]: data } : row)));
    };

    const checkForDuplicateKeyValues = (rowData: IRowData[], field: string) => {
        // Filter out rows where the Key is an empty string
        const filteredRowData = rowData.filter((row) => row.Key !== '');
        const keyValues = filteredRowData.map((row) => row.Key);
        const uniqueKeyValues = new Set(keyValues);

        if (field === 'Authorization Parameters' && keyValues.length !== uniqueKeyValues.size) {
            setAuthorizationError(true);
            return;
        } else if (field === 'Access Token Parameters' && keyValues.length !== uniqueKeyValues.size) {
            setAccessTokenError(true);
            return;
        }

        setAccessTokenError(false);
        setAuthorizationError(false);
    };

    const transformRowDataToObject = (rowData: IRowData[]) => {
        return rowData.reduce((obj: Record<string, string>, item: IRowData) => {
            obj[item.Key] = item.Value;
            return obj;
        }, {});
    };

    const checkKeyIsEmpty = (rowData: IRowData[]) => {
        const emptyKeyInRowData = rowData.filter((row) => row.Key === '');
        emptyKeyInRowData.length > 0 ? setKeyEmptyError(true) : setKeyEmptyError(false);
    };

    useEffect(() => {
        checkForDuplicateKeyValues(defaultRowData, field.label);
        checkKeyIsEmpty(defaultRowData);
        if (field.label === 'Authorization Parameters') {
            dispatch(setAuthorizationParameterValue([...defaultRowData]));
            const authorizationParamData = transformRowDataToObject(defaultRowData);
            setValue(`${formIndex}.oauthOptions/OAuth2Config/authorizationParameters`, authorizationParamData);
        } else {
            dispatch(setAccessTokenParameterValue([...defaultRowData]));
            const accessTokenParamData = transformRowDataToObject(defaultRowData);
            setValue(`${formIndex}.oauthOptions/OAuth2Config/accessTokenParameters`, accessTokenParamData);
        }
    }, [defaultRowData]);

    useEffect(() => {
        if (value) {
            let parsedInputValue;
            if (typeof value === 'string') {
                try {
                    parsedInputValue = JSON.parse(value);
                } catch (error) {
                    console.error('Invalid JSON string:', value);
                    return;
                }
            } else if (typeof value === 'object') {
                parsedInputValue = value;
            } else {
                console.error('Unexpected value type:', typeof value);
                return;
            }

            const rowDataArray: IRowData[] = Object.entries(parsedInputValue).map(([key, value], index) => ({
                id: index + 1,
                Key: key,
                Value: value as string,
            }));
            setDefaultRowData(rowDataArray);
            setIndex(rowDataArray.length);
        } else {
            setDefaultRowData([]);
        }
    }, [value]);

    return { defaultRowData, handlePropertyAdd, handleDelete, handleRowData, index, accessTokenError, authorizationError, keyEmptyError };
};

export default useConfigureConnectionTable;
