export const extractFormIndex = (inputString: string) => {
    const [formIndex] = inputString.split('.');
    return formIndex;
};

export const findOAuthClientSecretIndex = (data: any): string[] => {
    const indices: string[] = [];

    for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
            const value = data[key];
            if (value && typeof value === 'object' && 'oauthOptions/OAuth2Config/credentials/@clientSecret' in value) {
                indices.push(key);
            }
        }
    }

    return indices;
};
