import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IConnection, IExtendedConfigureConnectionData } from 'interfaces/IConfigureConnection';
import useModalStatusScroll from 'hooks/useModalStatusScroll';
import { setOAuthConnectorDetails } from 'services/getDynamicQuery';
import { IStore } from 'interfaces/IStore';
import { reorderFields } from 'utils/reorderConnectionFields';
import { setTokenGeneratedStatus } from '../store/actions/generalActions';

const useExtendedConfigureConnection = ({
    connection,
    setConnection,
    oauthConnectorDetails,
    modalStatus,
    id,
    makeApiCall,
    environmentAttachments,
    processEnvironmentAttachments,
    process,
    getConnectionData,
}: IExtendedConfigureConnectionData) => {
    const dispatch = useDispatch();
    const { spaceGuid } = useSelector((state: IStore) => state.login);

    useModalStatusScroll(modalStatus);

    const { oauthTokenGeneratedStatus } = useSelector((state: IStore) => state.general || {});

    const addValuesToConnection = (connections: IConnection[], data: { [key: string]: any }): IConnection[] => {
        const connectorDetails = JSON.parse(data.oauthConnectorDetails);
        // We do not want to show client secret value
        delete connectorDetails['oauthOptions/OAuth2Config/credentials/@clientSecret'];

        return connections.map(connection => ({
            ...connection,
            field: connection.field.map((field : any) => {
                if (field.id === 'oauthOptions/OAuth2Config/credentials/@accessToken') {
                    return { ...field, value: data.isTokenGenerated };
                } else if (field.id in connectorDetails) {
                    return { ...field, value: connectorDetails[field.id] };
                }
                return field;
            })
        }));
    };

    const saveOAuthConnectorDetails = async (data: any) => {
        const catalogsvcPayload = { ...data };
        catalogsvcPayload.oauthConnectorDetails = catalogsvcPayload?.["0"];
        catalogsvcPayload.catalogGuid = spaceGuid;
        catalogsvcPayload.isTokenGenerated = oauthTokenGeneratedStatus ? oauthTokenGeneratedStatus : false;
        const { oAuthConnectorDetailsMutation } = setOAuthConnectorDetails(id, catalogsvcPayload);
        await makeApiCall(oAuthConnectorDetailsMutation, true);
    };

    const handleSaveOAuthConnectorDetails = async (data: any) => {
        if (data && data['0'] && 'baseUrl' in data['0']) {
            await saveOAuthConnectorDetails(data);
        }
    };

    useEffect(() => {
        environmentAttachments && processEnvironmentAttachments(environmentAttachments);
    }, [environmentAttachments]);

    useEffect(() => {
        if (process.length) {
            const connectionData: IConnection[] = getConnectionData(process);
            connectionData && setConnection(reorderFields([...connectionData]));
        }
    }, [process]);

    useEffect(() => {
        if (oauthConnectorDetails && oauthConnectorDetails.IPackInstanceOAuthConnectorDetails) {
            const updatedConnections = addValuesToConnection(connection, oauthConnectorDetails.IPackInstanceOAuthConnectorDetails);
            const isDifferent = !updatedConnections.every((conn, index) => JSON.stringify(conn) === JSON.stringify(connection[index]));
            isDifferent && setConnection(updatedConnections);
        }
    }, [connection, oauthConnectorDetails]);

    useEffect(() => {
        if (oauthConnectorDetails && oauthConnectorDetails.IPackInstanceOAuthConnectorDetails) {
            dispatch(setTokenGeneratedStatus(oauthConnectorDetails.IPackInstanceOAuthConnectorDetails.isTokenGenerated));
        } else {
            dispatch(setTokenGeneratedStatus(false));
        }
    }, [oauthConnectorDetails]);

    return { addValuesToConnection, saveOAuthConnectorDetails , handleSaveOAuthConnectorDetails };
};

export default useExtendedConfigureConnection;
